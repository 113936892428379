import { t } from '@zupr/i18n'
import { Field } from '@zupr/types/form'
import classnames from 'classnames'
import { InputHTMLAttributes, useCallback, useMemo } from 'react'

interface RenderInputProps extends InputHTMLAttributes<HTMLInputElement> {
    field: Field
    error?: string
}

const RenderInput = ({
    className,
    field,
    type,
    onBlur,
    maxLength,
    onChange,
    placeholder,
    error,
    autoComplete,
    autoCapitalize,
    disabled,
}: InputProps) => (
    <input
        disabled={disabled}
        className={classnames(className, { error })}
        value={field.value == null ? '' : field.value}
        type={type}
        onChange={onChange}
        name={field.name}
        id={field.name}
        onBlur={onBlur}
        placeholder={
            (placeholder && t(placeholder)) ||
            (field.placeholder && t(field.placeholder)) ||
            null
        }
        autoComplete={autoComplete}
        autoCapitalize={autoCapitalize}
        maxLength={maxLength || field.max_length}
    />
)

RenderInput.defaultProps = {
    autoComplete: 'off',
}

export interface InputProps extends RenderInputProps {
    counter?: boolean
}

// field is connected for now to test changes
const Input = ({ field, counter, onChange, ...props }: InputProps) => {
    const handleChange = useCallback(
        (ev) => {
            if (onChange) {
                return onChange(ev)
            }
            if (ev.defaultPrevented) return
            return field.setValue(ev.target.value)
        },
        [field, onChange]
    )

    const maxLength = useMemo(() => {
        if (field.max_length) return field.max_length

        if (
            field.max_digits &&
            field.value &&
            `${field.value}`.indexOf('.') > 0
        ) {
            return field.max_digits + 1
        }

        if (field.max_digits) return field.max_digits

        return null
    }, [field.max_digits, field.max_length, field.value])

    if (!counter)
        return (
            <RenderInput
                {...props}
                onChange={handleChange}
                maxLength={maxLength}
                field={field}
            />
        )

    return (
        <div className="input-counter">
            <RenderInput
                {...props}
                onChange={handleChange}
                maxLength={maxLength}
                field={field}
            />
            {field.max_length && counter && (
                <div className="max-length">
                    {field.max_length - (field.value || '').length}
                </div>
            )}
        </div>
    )
}

Input.defaultProps = {
    type: 'text',
}

export default Input
